const resources = {
  en: {
    translation: {

      //Menu
      home: "Home",
      about: "About me",
      skills: "Skills",
      experience: "Experience",
      work: "Projects",
      contact: "Contact",
      
      //Main Page
      name: "Hi, my name is",
      developer: "I am a developer",
      btn_projects: "Check out my Projects",
      btn_experience: "Check out my experience",

      //About Me
      intro:"Hi! My name is",
      descript:"I am an organized and highly motivated person, I am able to adapt to any circumstance and always give my best in any project. I love developing new applications. I have always been passionate about technology. I like to learn new programming languages and frameworks. In my free time I usually create new personal projects or take courses on Udemy or Coursera.",
     
      titletech:"Technologies",
      introskill:"//These are the technologies I have worked with:",
      introexperience: "//This is the work experience I have:",
      
      //Experience
      dateexp1:"March 2023 - Present",
      role1 :"Software Developer",
      experience1:"- Analysis of software requirements, Scrum meetings ,colaborate with different departments involved with the development ,develop software modules and APIs, queries and data manipulation in databases.",
      

      dateexp2:"August 2022 - March 2023",
      role2 :"QA Automation Engineer",
      experience2:"- Analysis of software requirements, collaboration between different teams, development of automated frameworks for testing web applications and manual testing of products.",
      

      dateexp3:"January 2022 - June 2022",
      role3 :"Full Stack Developer",
      experience3:"- Analysis of software requirements, API development, database queries, and data manipulation, designing and building web pages with different technologies and web performance optimization.",
      
      
      dateexp4:"March 2021 - June 2021",
      role4 :"Web Developer",
      experience4:"- Develop new functionalities for API's, web design with CSS and JavaScript for WordPress sites, queries and data manipulation in databases.",
     


      //Projects
      titproj:"Projects",
      introproj:"//Take a look at my most recent projects:",
      protit1:"Kotlin Android application",
      prodesc1:"Application developed with Kotlin for the management of routes, stops and drivers.",
      prodesc2:"Web for book management developed with React, Nodejs and Express.",
      protit3:"Nodejs API with Express",
      prodesc3:"API developed with Nodejs and Express for employee management.",
      and:"and",
      protit4:"InnovateERP",
      prodesc4:"Full stack web for business management, mainly people management.",

      //Contact
      conttit:"Contact",
      contintro:"//You can contact me through this form or my social networks",
      nameplch:"Name",
      emailplch:"Email",
      msgplch:"Message",
      btnform:"Send",

      //footer
      footdesc:"©Marius Romosan. All rights reserved",

    },
  },
  es: {
    translation: {
      //Menu
      home: "Inicio",
      about: "Sobre mí",
      skills: "Tecnologías",
      experience: "Experiencia",
      work: "Proyectos",
      contact: "Contacto",

      //Main page
      name: "Hola, mi nombre es",
      developer: "soy desarrollador",
      btn_projects: "Ver Proyectos",
      btn_experience: "Ver Experiencia",
      
      //About me 
      intro:"Hola! Mi nombre es",
      descript:"Soy una persona organizada y con gran motivación, soy capaz de daptarme a cualquier circunstancia y dar siempre lo mejor de mí en cualquier proyecto. Me encanta desarrollar nuevas aplicaciones. Desde siempre he sido un apasionado de la tecnología. Me gusta aprender nuevos lenguajes de programacion y otros frameworks. En mi tiempo libre suelo crear nuevos proyectos personales o hacer cursos en Udemy o Coursera.",
      titletech:"Tecnologías",
      introskill:"//Estas son las tecnologías con las que he trabajado:",

      //Experience
      dateexp1:"Marzo 2023 - Actualidad",
      role1 : "Desarrollador de software",
      experience1: "- Análisis de requisitos de software, reuniones Scrum, colaboración con diferentes departamentos involucrados en el desarrollo, desarrollo de módulos de software y APIs, consultas y manipulación de datos en bases de datos.",
    

      dateexp2:"Agosto 2022 - Marzo 2023",
      role2 :"QA Automation Engineer",
      experience2: "- Análisis de los requisitos de software, colaboración entre diferentes equipos, desarrollo de frameworks automatizados para probar aplicaciones web y testing manual de los productos.",
      

      dateexp3:"Enero 2022 - Junio 2022",
      role3 :"Desarrollador Full Stack",
      experience3: "Análisis de los requisitos de software, desarrollo de API, consultas y manipulación de datos en bases de datos, diseñar y construir páginas web con diferentes tecnologías y optimizar el rendimiento de la web.",
     

      dateexp4:"Marzo 2021 - Junio 2021",
      role4 :"Desarrollador Web",
      experience4: "- Desarrollo de nuevas funcionalidades para API's, diseño web con CSS y JavaScript para sitios WordPress, consultas y manipulación de datos en bases de datos.",
     

      //Projects
      titproj:"Proyectos",
      introproj:"//Echa un vistazo a mis proyectos más recientes:",
      protit1:"Aplicación Android en Kotlin",
      prodesc1:"Aplicación desarrollada con Kotlin para la gestión de rutas, paradas y conductores.",
      prodesc2:"Web para la gestión de libros desarrollada con React, Nodejs y Express.",
      protit3:"Nodejs API con Express",
      prodesc3:"API desarrollada con Nodejs y Express para la gestión de empleados.",
      and: "y",
      protit4:"InnovateERP",
      prodesc4:"Full stack web para la gestión de empresa, principalmente la gestión de personas.",

      //Contact
      conttit:"Contacto",
      contintro:"//Puedes contactarme a través de este formulario o de mis redes sociales",
      nameplch:"Nombre",
      emailplch:"Email",
      msgplch:"Mensaje",
      btnform:"Enviar",

      //Footer
      footdesc:"©Marius Romosan. Todos los derechos reservados",

    },
  },
};

export default resources;
