import React from "react";
import { useTranslation } from "react-i18next";


//TODO: Check why this section does not hide when mobile menu is active
const Experience = () => {
  const {t}=useTranslation();
  return (
    <div name="experience" className="bg-[#0a192f] text-gray-300 w-full h-full pb-20">
      <div className="max-w-[1000px] mx-auto p-4 pb-8 flex flex-col justify-center w-full h-full">
        <div>
          <p className="text-4xl font-bold  inline border-b-4 border-blue-500 py-4">
          {t('experience')}
          </p>
          <p className="py-8">
    
          </p>
        </div>
        <div class="w-full py-8 items-center justify-center">
            <ol class="relative border-l border--200 dark:border-gray-200 ">    
              <li class=" mb-10 ml-4">
                    <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-200"></div>
                    <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">{t('dateexp1')}</time>
                    <h3 class="text-lg font-semibold text-white">{t('role1')} - Arsys S.L.U</h3>
                    <p class="text-base font-normal text-gray-500 dark:text-gray-400 "> {t('experience1')}</p>
                <ul class="mt-2 flex flex-wrap sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4">
                    <li class="mt-2">
                      <div class="flex items-center rounded-full bg-blue-500 px-3 py-1 text-xs font-medium leading-5 text-grey-500">
                        PHP
                      </div>
                    </li>
                    <li class="mt-2 -m-5">
                      <div class="flex items-center rounded-full bg-blue-500 px-3 py-1 text-xs font-medium leading-5 text-grey-500">
                        Composer
                      </div>
                    </li>
                    <li class="mt-2">
                      <div class="flex items-center rounded-full bg-blue-500 px-3 py-1 text-xs font-medium leading-5 text-grey-500">
                        PHP-DI
                      </div>
                    </li>
                    <li class="mt-2 -m-5">
                      <div class="flex items-center rounded-full bg-blue-500 px-3 py-1 text-xs font-medium leading-5 text-grey-500">
                        Swagger-PHP
                      </div>
                    </li>
                    <li class="mt-2">
                      <div class="flex items-center rounded-full bg-blue-500 px-3 py-1 text-xs font-medium leading-5 text-grey-500">
                        DDD
                      </div>
                    </li>
                    <li class="mt-2 -m-5">
                      <div class="flex items-center rounded-full bg-blue-500 px-3 py-1 text-xs font-medium leading-5 text-grey-500">
                        Scrum
                      </div>
                    </li>
                </ul>
                </li>   

              <li class=" mb-10 ml-4">
                    <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-200"></div>
                    <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">{t('dateexp2')}</time>
                    <h3 class="text-lg font-semibold text-white">{t('role2')} - Arsys S.L.U</h3>
                    <p class="text-base font-normal text-gray-500 dark:text-gray-400 "> {t('experience2')}</p>
                <ul class="mt-2 flex flex-wrap ">
                    <li class="mt-2 ">
                      <div class="flex items-center rounded-full bg-blue-500 px-3 py-1 text-xs font-medium leading-5 text-grey-500">
                        JAVA
                      </div>
                    </li>
                    <li class="mt-2 -m-5">
                      <div class="flex items-center rounded-full bg-blue-500 px-3 py-1 text-xs font-medium leading-5 text-grey-500">
                        Selenium
                      </div>
                    </li>
                    <li class="mt-2 ">
                      <div class="flex items-center rounded-full bg-blue-500 px-3 py-1 text-xs font-medium leading-5 text-grey-500">
                        Maven
                      </div>
                    </li>
                    <li class="mt-2 -m-5">
                      <div class="flex items-center rounded-full bg-blue-500 px-3 py-1 text-xs font-medium leading-5 text-grey-500">
                        JUnit
                      </div>
                    </li>
                    <li class="mt-2">
                      <div class="flex items-center rounded-full bg-blue-500 px-3 py-1 text-xs font-medium leading-5 text-grey-500">
                        Cucumber
                      </div>
                    </li>
                    <li class="mt-2 -m-5">
                      <div class="flex items-center rounded-full bg-blue-500 px-3 py-1 text-xs font-medium leading-5 text-grey-500">
                        Docker
                      </div>
                    </li>
                    <li class="mt-2">
                      <div class="flex items-center rounded-full bg-blue-500 px-3 py-1 text-xs font-medium leading-5 text-grey-500">
                        Jenkins
                      </div>
                    </li>
                </ul>
                </li>

                <li class=" mb-10 ml-4">
                    <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-200"></div>
                    <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">{t('dateexp3')}</time>
                    <h3 class="text-lg font-semibold text-white">{t('role3')} - Kreare Mobile</h3>
                    <p class="text-base font-normal text-gray-500 dark:text-gray-400 "> {t('experience3')}</p>
                <ul class="mt-2 flex flex-wrap ">
                    <li class="mt-2">
                      <div class="flex items-center rounded-full bg-blue-500 px-3 py-1 text-xs font-medium leading-5 text-grey-500">
                        Symfony
                      </div>
                    </li>
                    <li class="mt-2 -m-5">
                      <div class="flex items-center rounded-full bg-blue-500 px-3 py-1 text-xs font-medium leading-5 text-grey-500">
                        Doctrine ORM
                      </div>
                    </li>
                    <li class="mt-2">
                      <div class="flex items-center rounded-full bg-blue-500 px-3 py-1 text-xs font-medium leading-5 text-grey-500">
                        Angular
                      </div>
                    </li>
                    <li class="mt-2 -m-5">
                      <div class="flex items-center rounded-full bg-blue-500 px-3 py-1 text-xs font-medium leading-5 text-grey-500">
                        Bootstrap
                      </div>
                    </li>
                    <li class="mt-2">
                      <div class="flex items-center rounded-full bg-blue-500 px-3 py-1 text-xs font-medium leading-5 text-grey-500">
                        BitBucket
                      </div>
                    </li>
                </ul>
                </li>

                <li class="mb-10 ml-4">
                    <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-200"></div>
                    <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">{t('dateexp4')}</time>
                    <h3 class="text-lg font-semibold text-white">{t('role4')} - SDi Digital Group</h3>
                    <p class="text-base font-normal text-gray-500 dark:text-gray-400 "> {t('experience4')}</p>
                <ul class="mt-2 flex flex-wrap ">
                    <li class="mt-2">
                      <div class="flex items-center rounded-full bg-blue-500 px-3 py-1 text-xs font-medium leading-5 text-grey-500">
                        PHP
                      </div>
                    </li>
                    <li class="mt-2 -m-5">
                      <div class="flex items-center rounded-full bg-blue-500 px-3 py-1 text-xs font-medium leading-5 text-grey-500">
                        JavaScript
                      </div>
                    </li>
                    <li class="mt-2">
                      <div class="flex items-center rounded-full bg-blue-500 px-3 py-1 text-xs font-medium leading-5 text-grey-500">
                        CSS
                      </div>
                    </li>
                    <li class="mt-2 -m-5">
                      <div class="flex items-center rounded-full bg-blue-500 px-3 py-1 text-xs font-medium leading-5 text-grey-500">
                        HTML
                      </div>
                    </li>
                    <li class="mt-2">
                      <div class="flex items-center rounded-full bg-blue-500 px-3 py-1 text-xs font-medium leading-5 text-grey-500">
                        WordPress
                      </div>
                    </li>
                </ul>
                </li>
            </ol>
        </div>
      </div>
    </div>
  );
};

export default Experience;